<template>
	<v-row>
<!--		<DialogDashboards :dialog="dialog" />-->
		<v-col cols="12" md="12">
			<v-card>
				<v-card-text>
					<v-form>
						<v-row class="mb-n10">
							<v-col class="d-none d-md-block pl-2 pr-1 pl-lg-3">
								<InputDatePicker
									:disabled="carregando"
									v-model="busca.dtinicio"
									label="Data inicial"
									:outlined="true"
									:dense="true"
								/>
							</v-col>
							<v-col class="d-none d-md-block px-1">
								<InputDatePicker
									:disabled="carregando"
									v-model="busca.dtfim"
									label="Data final"
									:outlined="true"
									:dense="true"
								/>
							</v-col>
							<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
								<v-sheet class="text-center" height="100%">
									<v-row class="pa-3">
										<v-col cols="6">
											<InputDatePicker
												:disabled="carregando"
												v-model="busca.dtinicio"
												label="Data inicial"
												:outlined="true"
												:dense="true"
											/>
										</v-col>
										<v-col cols="6">
											<InputDatePicker
												:disabled="carregando"
												v-model="busca.dtfim"
												label="Data final"
												:outlined="true"
												:dense="true"
											/>
										</v-col>
										<v-col cols="6">
											<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
										</v-col>
										<v-col cols="6">
											<v-btn
												text
												class="mt-n3"
												color="primary"
												@click="sheet = !sheet"
												:disabled="carregando"
											>Filtrar</v-btn>
										</v-col>
									</v-row>
								</v-sheet>
							</v-bottom-sheet>
							<v-col class="px-1 pl-md-1 pr-lg-3 d-flex">
								<v-autocomplete
									class="d-inline-flex d-md-none"
									:items="filiaisFiltro"
									clearable
									item-text="filial"
									item-value="idfilial"
									label="Filial"
									outlined
									dense
									:disabled="carregando"
									v-model="busca.idfilial"
									append-outer-icon="mdi-menu"
									@click:append-outer="sheet = !sheet"
								></v-autocomplete>
								<v-autocomplete
									class="d-none d-md-block"
									:items="filiaisFiltro"
									clearable
									item-text="filial"
									item-value="idfilial"
									label="Filial"
									outlined
									dense
									:disabled="carregando"
									v-model="busca.idfilial"
								></v-autocomplete>
								<v-btn
									:disabled="carregando"
									class="ml-2 mt-1"
									color="primary"
									elevation="0"
									fab
									x-small

								>
									<v-icon>mdi-magnify</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
			</v-card>
		</v-col>
    <v-col cols="12" md="12">
      <v-row class="pa-2">
        <v-col class="pa-1">
          <CardDashboard :loading="carregando" title="Total de gaiolas movimentadas no período">
            <template v-if="dados.ticketsabertos">{{dados.ticketsabertos}}</template>
            <template v-else>35</template>
          </CardDashboard>
        </v-col>
        <v-col class="pa-1">
          <CardDashboard :loading="carregando" title="Total de paletes movimentados no período">
            <template v-if="dados.ticketsabertos">{{dados.ticketsabertos}}</template>
            <template v-else>243</template>
          </CardDashboard>
        </v-col>
        <v-col class="pa-1">
          <CardDashboard :loading="carregando" title="Tempo médio com o equipamento gaiola parada">
            <template v-if="dados.ticketsabertos">{{dados.ticketsabertos}}</template>
            <template v-else>10 Dias e 2 Horas</template>
          </CardDashboard>
        </v-col>
      </v-row>
    </v-col>
    <template v-if="dados.ticketsfilial">
      <v-col cols="12" v-if="dados.ticketsfilial.length > 1">
        <v-card class="primary white--text">
          <v-progress-linear indeterminate absolute :active="carregando" />
          <v-card-title class="d-inline-block col-12 text-truncate">Ranking de Gaiolas por Tempo Parada</v-card-title>
          <v-card-text v-if="carregando">
            <v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
          </v-card-text>
          <v-card-text v-else>
            <v-data-table
                dense
                sort-by="tempo"
                :sort-desc="true"
                hide-default-footer
                class="elevation-0"
                fixed-header
                :headers="headers"
                :items="data"
                :items-per-page="17"
            >
              <template v-slot:item.tempo="{ item }">
                <v-chip small :class="item.tempo > 10 ? 'error' : 'warning'"><span>{{ item.tempo }} Dias</span></v-chip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
    <template v-if="dados.ticketsfilial">
      <v-col cols="12" v-if="dados.ticketsfilial.length > 1">
        <v-card class="primary white--text px-4 pb-4">
          <v-progress-linear indeterminate absolute :active="carregando" />
          <v-card-title class="d-inline-block col-12 text-truncate px-0">Envio de Paletes para Filiais por Período</v-card-title>
          <v-card-text v-if="carregando">
            <v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
          </v-card-text>
          <v-card-text v-else class="white pa-0">
            <GraficoBarDashboard
              v-if="dados.ticketsfilial"
              :dataSeries="[
                {
                  name: 'Filial',
                  data: dados.ticketsfilial.map(v => {return v.qtdticket})
                },
              ]"
              :categories="dados.ticketsfilial.map(v => {return v.filial})"
              :horizontal="false"
              :money="false"
              :porcentagem="false"
              height="300"
              :distributed="true"
              :dataLabels="{ position : 'bottom', orientation : 'horizontal', offsetY: 20 }"
              :color="corMod2(dados.ticketsfilial, 'qtdticket')"
            />
            <span v-else>Nenhum registro encontrado</span>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
<!--		<v-col cols="12" md="12">-->
<!--			<v-row class="pa-2">-->
<!--				<v-col class="pa-1">-->
<!--					<CardDashboard :loading="carregando" title="Total de tickets">-->
<!--						<template v-if="dados.totaltickets">{{dados.totaltickets}}</template>-->
<!--						<template v-else>0</template>-->
<!--					</CardDashboard>-->
<!--				</v-col>-->
<!--				<v-col class="pa-1">-->
<!--					<CardDashboard :loading="carregando" title="Tickets abertos">-->
<!--						<template v-if="dados.ticketsabertos">{{dados.ticketsabertos}}</template>-->
<!--						<template v-else>0</template>-->
<!--					</CardDashboard>-->
<!--				</v-col>-->
<!--				<v-col class="pa-1">-->
<!--				<v-col class="pa-1">-->
<!--					<CardDashboard :loading="carregando" title="Tickets em andamento">-->
<!--						<template v-if="dados.ticketsemandamento">{{dados.ticketsemandamento}}</template>-->
<!--						<template v-else>0</template>-->
<!--					</CardDashboard>-->
<!--				</v-col>-->
<!--				<v-col class="pa-1">-->
<!--					<CardDashboard :loading="carregando" title="Tickets aguardando terceiros">-->
<!--						<template v-if="dados.ticketseguardandoterceiros">{{dados.ticketseguardandoterceiros}}</template>-->
<!--						<template v-else>0</template>-->
<!--					</CardDashboard>-->
<!--				</v-col>-->
<!--				<v-col class="pa-1">-->
<!--					<CardDashboard :loading="carregando" title="Tickets concluídos">-->
<!--						<template v-if="dados.ticketconcluidos">{{dados.ticketconcluidos}}</template>-->
<!--						<template v-else>0</template>-->
<!--					</CardDashboard>-->
<!--				</v-col>-->
<!--				<v-col class="pa-1">-->
<!--					<CardDashboard :loading="carregando" title="Média tempo de atendimento">-->
<!--						<template v-if="dados.mediatempoatendimento">-->
<!--							<template-->
<!--								v-if="dados.mediatempoatendimento < 1"-->
<!--							>{{(dados.mediatempoatendimento * 60).toFixed(0)}} Minutos</template>-->
<!--							<template v-else>{{(dados.mediatempoatendimento).toFixed(0)}} Horas</template>-->
<!--						</template>-->
<!--						<template v-else>0</template>-->
<!--					</CardDashboard>-->
<!--				</v-col>-->
<!--			</v-row>-->
<!--		</v-col>-->
<!--		<template v-if="dados.ticketsfilial">-->
<!--			<v-col cols="12" v-if="dados.ticketsfilial.length > 1">-->
<!--				<v-card>-->
<!--					<v-progress-linear indeterminate absolute :active="carregando" />-->
<!--					<v-card-title class="d-inline-block col-12 text-truncate">Tickets por filial</v-card-title>-->
<!--					<v-card-text v-if="carregando">-->
<!--						<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>-->
<!--					</v-card-text>-->
<!--					<v-card-text v-else>-->
<!--						<GraficoBarDashboard-->
<!--							v-if="dados.ticketsfilial"-->
<!--							:dataSeries="[-->
<!--								{-->
<!--									name: 'Filial',-->
<!--									data: dados.ticketsfilial.map(v => {return v.qtdticket})-->
<!--								},-->
<!--							]"-->
<!--							:categories="dados.ticketsfilial.map(v => {return v.filial})"-->
<!--							:horizontal="false"-->
<!--							:money="false"-->
<!--							:porcentagem="false"-->
<!--							height="300"-->
<!--							:distributed="true"-->
<!--							:dataLabels="{ position : 'bottom', orientation : 'horizontal', offsetY: 20 }"-->
<!--							:color="corMod2(dados.ticketsfilial, 'qtdticket')"-->
<!--						/>-->
<!--						<span v-else>Nenhum registro encontrado</span>-->
<!--					</v-card-text>-->
<!--				</v-card>-->
<!--			</v-col>-->
<!--		</template>-->
<!--		<v-col cols="6">-->
<!--			<v-card>-->
<!--				<v-progress-linear indeterminate absolute :active="carregando" />-->
<!--				<v-card-title class="d-inline-block col-12 text-truncate">Tickets por categoria</v-card-title>-->
<!--				<v-card-text v-if="carregando">-->
<!--					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>-->
<!--				</v-card-text>-->
<!--				<v-card-text v-else>-->
<!--					<GraficoBarDashboard-->
<!--						v-if="dados.ticketscategoria"-->
<!--						:dataSeries="[-->
<!--							{-->
<!--							name: 'Tickets',-->
<!--							data: ticketsQuantidade(dados.ticketscategoria.map(v => {return v.qtdticket}))-->
<!--							},-->
<!--						]"-->
<!--						:categories="ticketsNome(dados.ticketscategoria.map(v => {return v.categoria}))"-->
<!--						:horizontal="false"-->
<!--						:money="false"-->
<!--						:porcentagem="false"-->
<!--						height="300"-->
<!--						:distributed="true"-->
<!--						:dataLabels="{ position : 'bottom', orientation : 'horizontal', offsetY: 20 }"-->
<!--						:color="corMod2(dados.ticketscategoria, 'qtdticket')"-->
<!--					/>-->
<!--					<span v-else>Nenhum registro encontrado</span>-->
<!--				</v-card-text>-->
<!--			</v-card>-->
<!--		</v-col>-->
<!--		<v-col cols="6">-->
<!--			<v-card>-->
<!--				<v-progress-linear indeterminate absolute :active="carregando" />-->
<!--				<v-card-title class="d-inline-block col-12 text-truncate">-->
<!--					<v-row>-->
<!--						<v-col class="text-truncate">Tickets por subcategoria</v-col>-->
<!--						<v-col v-if="dados.ticketscategoria">-->
<!--							<v-select-->
<!--								class="mb-n10"-->
<!--								dense-->
<!--								outlined-->
<!--								:items="dados.ticketscategoria"-->
<!--								item-text="categoria"-->
<!--								item-value="categoria"-->
<!--								v-model="categoria"-->
<!--							></v-select>-->
<!--						</v-col>-->
<!--					</v-row>-->
<!--				</v-card-title>-->
<!--				<v-card-text v-if="carregando">-->
<!--					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>-->
<!--				</v-card-text>-->
<!--				<template v-else>-->
<!--					<v-card-text-->
<!--						class="pt-0 mt-0"-->
<!--						v-for="(d, i) in dados.ticketscategoria"-->
<!--						:key="i"-->
<!--						v-show="d.categoria == categoria"-->
<!--					>-->
<!--						<GraficoBarDashboard-->
<!--							v-if="d.categoria == categoria"-->
<!--							:dataSeries="[{-->
<!--								name: 'Tickets',-->
<!--								data: ticketsQuantidade(d.subcategorias.map(v => {return v.qtdticket}))-->
<!--							}]"-->
<!--							:categories="ticketsNome(d.subcategorias.map(v => {return v.subcategoria}))"-->
<!--							:horizontal="false"-->
<!--							:money="false"-->
<!--							:porcentagem="false"-->
<!--							height="300"-->
<!--							:distributed="true"-->
<!--							:dataLabels="{ position : 'bottom', orientation : 'horizontal', offsetY: 20 }"-->
<!--							:color="corMod2(d.subcategorias, 'qtdticket')"-->
<!--						/>-->
<!--					</v-card-text>-->
<!--					<v-card-text v-if="!dados.ticketscategoria">-->
<!--						<span>Nenhum registro encontrado</span>-->
<!--					</v-card-text>-->
<!--				</template>-->
<!--			</v-card>-->
<!--		</v-col>-->
<!--		<v-col cols="6">-->
<!--			<v-card>-->
<!--				<v-progress-linear indeterminate absolute :active="carregando" />-->
<!--				<v-card-title class="d-flex col-12 text-truncate">-->
<!--					Tickets por solicitante chamado-->
<!--					<v-spacer />-->
<!--					<v-btn-->
<!--						:disabled="carregando"-->
<!--						class="px-2 mb-1 ml-auto mt-2 mr-2"-->
<!--						color="primary"-->
<!--						elevation="0"-->
<!--						small-->
<!--						@click="abrirDialog(-->
<!--                            dados.ticketssolicitante, -->
<!--                            {-->
<!--                                solicitante: {nome: 'Solicitante', tipo: 'texto'},-->
<!--                                qtdticket: {nome: 'Quantidade de Tickets', tipo: 'texto'},-->
<!--                            },-->
<!--                            'Tickets por solicitante chamado'-->
<!--                        )"-->
<!--					>-->
<!--						<v-icon size="18">mdi-magnify-plus-outline</v-icon>Ver mais-->
<!--					</v-btn>-->
<!--				</v-card-title>-->
<!--				<v-card-text v-if="carregando">-->
<!--					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>-->
<!--				</v-card-text>-->
<!--				<v-card-text v-else>-->
<!--					<GraficoBarDashboard-->
<!--						v-if="dados.ticketssolicitante"-->
<!--						:dataSeries="[{-->
<!--							name: 'Tickets',-->
<!--							data: ticketsQuantidade(dados.ticketssolicitante.map(v => {return v.qtdticket}))-->
<!--						}]"-->
<!--						:categories="ticketsNome(dados.ticketssolicitante.map(v => {return v.solicitante}))"-->
<!--						:horizontal="false"-->
<!--						:money="false"-->
<!--						:porcentagem="false"-->
<!--						height="300"-->
<!--						:distributed="true"-->
<!--						:dataLabels="{ position : 'bottom', orientation : 'horizontal', offsetY: 20 }"-->
<!--						:color="corMod2(dados.ticketssolicitante, 'qtdticket')"-->
<!--					/>-->
<!--					<span v-else>Nenhum registro encontrado</span>-->
<!--				</v-card-text>-->
<!--			</v-card>-->
<!--		</v-col>-->
<!--		<v-col cols="6">-->
<!--			<v-card>-->
<!--				<v-progress-linear indeterminate absolute :active="carregando" />-->
<!--				<v-card-title class="d-flex col-12 text-truncate">-->
<!--					Tickets por atendente suporte-->
<!--					<v-spacer />-->
<!--					<v-btn-->
<!--						:disabled="carregando"-->
<!--						class="px-2 mb-1 ml-auto mt-2 mr-2"-->
<!--						color="primary"-->
<!--						elevation="0"-->
<!--						small-->
<!--						@click="abrirDialog(-->
<!--                            dados.ticketsatendente, -->
<!--                            {-->
<!--                                atendente: {nome: 'Atendente', tipo: 'texto'},-->
<!--                                qtdticket: {nome: 'Quantidade de Tickets', tipo: 'texto'},-->
<!--                            },-->
<!--                            'Tickets por atendente chamado'-->
<!--                        )"-->
<!--					>-->
<!--						<v-icon size="18">mdi-magnify-plus-outline</v-icon>Ver mais-->
<!--					</v-btn>-->
<!--				</v-card-title>-->
<!--				<v-card-text v-if="carregando">-->
<!--					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>-->
<!--				</v-card-text>-->
<!--				<v-card-text v-else>-->
<!--					<GraficoBarDashboard-->
<!--						v-if="dados.ticketsatendente"-->
<!--						:dataSeries="[{-->
<!--							name: 'Tickets',-->
<!--							data: ticketsQuantidade(dados.ticketsatendente.map(v => {return v.qtdticket}))-->
<!--						}]"-->
<!--						:categories="ticketsNome(dados.ticketsatendente.map(v => {return v.atendente}))"-->
<!--						:horizontal="false"-->
<!--						:money="false"-->
<!--						:porcentagem="false"-->
<!--						height="300"-->
<!--						:distributed="true"-->
<!--						:dataLabels="{ position : 'bottom', orientation : 'horizontal', offsetY: 20 }"-->
<!--						:color="corMod2(dados.ticketsatendente, 'qtdticket')"-->
<!--					/>-->
<!--					<span v-else></span>-->
<!--				</v-card-text>-->
<!--			</v-card>-->
<!--		</v-col>-->
<!--		<v-col cols="12">-->
<!--			<v-card>-->
<!--				<v-progress-linear indeterminate absolute :active="carregando" />-->
<!--				<v-card-title class="d-flex col-12 text-truncate">Resumo analítico</v-card-title>-->
<!--				<v-divider />-->
<!--				<v-card-text>-->
<!--					<v-simple-table dense>-->
<!--						<thead>-->
<!--							<tr>-->
<!--								<th>ID</th>-->
<!--								<th>Data abertura</th>-->
<!--								<th>Data fechamento</th>-->
<!--								<th>Status</th>-->
<!--								<th>Solicitante</th>-->
<!--								<th>Atendente</th>-->
<!--								<th>Filial</th>-->
<!--								<th>Departamento</th>-->
<!--								<th>Categoria</th>-->
<!--								<th>Subcategoria</th>-->
<!--							</tr>-->
<!--						</thead>-->
<!--						<tbody v-if="dados.ticketsgeral">-->
<!--							<tr v-for="u,i in dados.ticketsgeral" :key="i">-->
<!--								<td>{{u.idchamado}}</td>-->
<!--								<td>{{u.dataabertura}}</td>-->
<!--								<td>{{u.dataconclusao}}</td>-->
<!--								<td>{{u.status}}</td>-->
<!--								<td>{{u.usuariocadastro}}</td>-->
<!--								<td>{{u.usuarioconclusao}}</td>-->
<!--								<td>{{u.filial}}</td>-->
<!--								<td>{{u.departamento}}</td>-->
<!--								<td>{{u.categoria}}</td>-->
<!--								<td>{{u.subcategoria}}</td>-->
<!--							</tr>-->
<!--						</tbody>-->
<!--						<tbody v-else>-->
<!--							<tr>-->
<!--								<td colspan="13" class="text-center">Nenhum registro encontrado.</td>-->
<!--							</tr>-->
<!--						</tbody>-->
<!--					</v-simple-table>-->
<!--				</v-card-text>-->
<!--			</v-card>-->
<!--		</v-col>-->
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import InputDatePicker from "../Widgets/InputDatePicker";
import CardDashboard from "../Widgets/CardDashboard";
import GraficoBarDashboard from "../Widgets/GraficoBarDashboard";
// import DialogDashboards from "../Widgets/DialogDashboards";

export default {
	name: "ChamadoDashboard",
	mixins: [mixinFilial],
	components: {
		InputDatePicker,
		GraficoBarDashboard,
		CardDashboard,
	// 	DialogDashboards,
	},
	data: () => ({
		sheet: false,
		carregando: false,
		filiais: {},
		departamentos: { lista: [] },
		categoria: "",
		dados: {
      ticketsfilial: [
        {
          filial: "Ivaiporã",
          qtdticket: 17
        },
        {
          filial: "\u001a Laranjeiras do Sul",
          qtdticket: 16
        },
        {
          filial: "Loja Reserva (Loja 31)",
          qtdticket: 13
        },
        {
          filial: "Rolândia",
          qtdticket: 10
        },
        {
          filial: "Mandaguari",
          qtdticket: 8
        },
        {
          filial: "\u001a Centro de Distribuição 39",
          qtdticket: 7
        },
        {
          filial: "Maringá",
          qtdticket: 7
        },
        {
          filial: "Paranavai",
          qtdticket: 6
        },
        {
          filial: "Faxinal",
          qtdticket: 6
        },
        {
          filial: "Campo Mourão",
          qtdticket: 5
        },
        {
          filial: "Telemaco Borba",
          qtdticket: 5
        },
        {
          filial: "Pitanga",
          qtdticket: 5
        },
        {
          filial: "Ibiporã",
          qtdticket: 4
        },
        {
          filial: "Cambé",
          qtdticket: 4
        },
        {
          filial: "Roncador",
          qtdticket: 3
        },
        {
          filial: "Manoel Ribas",
          qtdticket: 3
        },
        {
          filial: "Astorga (Loja 28)",
          qtdticket: 3
        },
        {
          filial: "\u001a Logística Reserva",
          qtdticket: 3
        },
        {
          filial: "Castelo",
          qtdticket: 3
        },
        {
          filial: "Cd",
          qtdticket: 3
        },
        {
          filial: "Jardim Alegre",
          qtdticket: 2
        },
        {
          filial: "Turvo",
          qtdticket: 2
        },
        {
          filial: "São João do Triunfo",
          qtdticket: 2
        },
        {
          filial: "Cianorte",
          qtdticket: 2
        },
        {
          filial: "Palmital",
          qtdticket: 2
        },
        {
          filial: "São João do Ivai",
          qtdticket: 1
        },
        {
          filial: "Guarapuava",
          qtdticket: 1
        },
        {
          filial: "São Mateus do Sul",
          qtdticket: 1
        }
      ],
    },
    headers: [
      { text: 'Codigo Filial', value: 'idFilial',sortable: false  },
      { text: 'Filial', value: 'filial', sortable: false  },
      { text: 'Codigo Gaiola', value: 'CodigoGaiola', sortable: false  },
      { text: 'Tempo', value: 'tempo', sortable: false  },
    ],
    data: [
      { idFilial: '1', filial: 'Filial A', CodigoGaiola: '000034', tempo: 10 },
      { idFilial: '2', filial: 'Filial B', CodigoGaiola: '000115', tempo: 24 },
      { idFilial: '3', filial: 'Filial C', CodigoGaiola: '000006', tempo: 39 },
      { idFilial: '4', filial: 'Filial D', CodigoGaiola: '000078', tempo: 15 },
      { idFilial: '5', filial: 'Filial E', CodigoGaiola: '000122', tempo: 8 },
      { idFilial: '6', filial: 'Filial F', CodigoGaiola: '000099', tempo: 30 },
      { idFilial: '9', filial: 'Filial G', CodigoGaiola: '000045', tempo: 20 },
      { idFilial: '16', filial: 'Filial H', CodigoGaiola: '000067', tempo: 17 },
      { idFilial: '11', filial: 'Filial K', CodigoGaiola: '000789', tempo: 19 },
      { idFilial: '12', filial: 'Filial L', CodigoGaiola: '000456', tempo: 32 },
      { idFilial: '13', filial: 'Filial M', CodigoGaiola: '000987', tempo: 7 },
      { idFilial: '14', filial: 'Filial N', CodigoGaiola: '000654', tempo: 42 },
      { idFilial: '15', filial: 'Filial O', CodigoGaiola: '000321', tempo: 5 },
      { idFilial: '17', filial: 'Filial P', CodigoGaiola: '000111', tempo: 18 },
      { idFilial: '18', filial: 'Filial Q', CodigoGaiola: '000222', tempo: 29 },
      { idFilial: '19', filial: 'Filial R', CodigoGaiola: '000333', tempo: 14 },
      { idFilial: '20', filial: 'Filial S', CodigoGaiola: '000444', tempo: 23 },
      // Adicione mais linhas de dados conforme necessário
    ],

    dialog: { status: false, header: {}, dados: [] },
		busca: {
			dtinicio: `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-01`,
			dtfim: `${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getDate()}`,
		},
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 37
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		get() {
			// this.carregando = true;
			// return axios
			// 	.post(`${this.backendUrl}chamado/dashboard`, {
			// 		idfilial: this.busca.idfilial || null,
			// 		dtini: `${this.busca.dtinicio} 00:00:00`,
			// 		dtfim: `${this.busca.dtfim} 23:59:59`,
			// 		iddepartamento:
			// 			this.usuario.iddepartamento == 1
			// 				? this.busca.iddepartamento
			// 				: this.usuario.iddepartamento || null,
			// 	})
			// 	.then((res) => {
			// 		this.dados = res.data.dashboard;
			// 		this.categoria = this.dados.ticketscategoria[0].categoria;
			// 		this.carregando = false;
			// 	})
			// 	.catch(() => {
			// 		this.dados = { total: 0 };
			// 		this.carregando = false;
			// 	});
		},
		listarDepartamentos() {
			this.departamentos.lista = [];
			return axios
				.post(`${this.backendUrl}departamento/listar`, {
					limit: 9999,
					offset: 0,
					busca: null,
				})
				.then((res) => {
					if (res.data.lista != null) {
						this.departamentos = res.data;
					}
				});
		},
		abrirDialog(dados, header, title) {
			this.dialog.status = true;
			this.dialog.dados = dados;
			this.dialog.header = header;
			this.dialog.title = title;
		},
		ticketsNome(arr) {
			let retorno;
			retorno = arr.slice(0, 12);
			if (arr.length > 12) {
				retorno.push("OUTROS");
			}
			return retorno;
		},
		ticketsQuantidade(arr) {
			let retorno;
			retorno = arr.slice(0, 12);
			if (arr.length > 12 && arr.length != 13) {
				retorno.push(arr.slice(13).reduce((i, v) => i + v));
			}
			return retorno;
		},
		corMod1(array, campo) {
			let cores = [];
			cores = array.map((v) => {
				let cor;
				cor = this.corSemafaro(v[campo]);
				return this.shadeColor(cor, 100 - v[campo] / 10);
			});
			return cores;
		},
		corMod2(array, campo) {
			let cores = [];
			cores = array.map((v) => {
				let cor = "#002D56";
				return this.shadeColor(cor, 100 - v[campo] / 10);
			});
			return cores;
		},
		corSemafaro(valor) {
			let cor;
			if (valor >= 90) cor = "#002D56";
			if (valor < 90) cor = "#F2B90F";
			if (valor < 70) cor = "#951015";
			return cor;
		},
		shadeColor(color, percent) {
			var R = parseInt(color.substring(1, 3), 16);
			var G = parseInt(color.substring(3, 5), 16);
			var B = parseInt(color.substring(5, 7), 16);
			R = parseInt((R * (100 + percent)) / 100);
			G = parseInt((G * (100 + percent)) / 100);
			B = parseInt((B * (100 + percent)) / 100);
			R = R < 255 ? R : 255;
			G = G < 255 ? G : 255;
			B = B < 255 ? B : 255;
			var RR =
				R.toString(16).length == 1
					? "0" + R.toString(16)
					: R.toString(16);
			var GG =
				G.toString(16).length == 1
					? "0" + G.toString(16)
					: G.toString(16);
			var BB =
				B.toString(16).length == 1
					? "0" + B.toString(16)
					: B.toString(16);
			return "#" + RR + GG + BB;
		},
		async init() {
			this.busca.iddepartamento = this.usuario.iddepartamento;
			await this.get();
			await this.listarDepartamentos();
		},
	},
	watch: {
		"busca.dtfim": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtinicio = this.busca.dtfim;
			}
		},
		"busca.dtinicio": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtfim = this.busca.dtinicio;
			}
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>

</style>